import store from './store';
import router from './router';
import * as Sentry from '@sentry/vue';

const sentryConfig = {
  app,
  dsn: 'https://2d055a4306a59d932c586b475d3b63f1@o4507183983755264.ingest.us.sentry.io/4507183983886336',
  environment: 'local',
  release: 'vh_vue3@' + import.meta.env.VITE_APP_VERSION,
  dist: import.meta.env.VITE_APP_VERSION,
  ignoreTransactions: ['Login', 'Logout'],
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'ui.click') {
      const target = hint.event.target;
      if (target.ariaLabel) breadcrumb.message = target.ariaLabel;
    }
    if (breadcrumb.message.match(['Vue', [/Vue/], 'MIXPANEL', [/MIXPANEL/], 'sentryFormWidget'])) {
      return null;
    }
    if ((breadcrumb.level === 'info' || breadcrumb.level === 'warning') && breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  },
  beforeSend: (event) => {
    if (store.getters.loggedInUser?.id) {
      const email = store.getters.loggedInUser?.email;
      const obscuredEmail = email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');
      const userPage = 'https://app.virtualhallway.ca/admin/viewuser/' + store.getters.loggedInUser?.id;
      event.user = {
        id: store.getters.loggedInUser?.id,
        username: obscuredEmail,
        role: store.getters.loggedInUser?.role,
        status: store.getters.loggedInUser?.status,
        province: store.getters.loggedInUser?.practice_province?.short_name,
        adminLink: userPage,
        route: window.location.href,
        screen: `${window.screen.width} x ${window.screen.height}`,
      };
    }
    let searchParams = new URLSearchParams(window.location.search); //don't send error if autoLogout query is present in the url
    if (!searchParams.has('autoLogout')) {
      return event;
    }
    // console.log(event);
  },
  trackComponents: ['App', 'MakeReferral', 'Home', 'SidebarConsultCards', 'EditReferralForm'],
  tracePropagationTargets: ['localhost', /^http:\/\/localhost:8080/, /^http:\/\/127.0.0.1:8000\/api/, /^https:\/\/app.virtualhallway\.ca\/api/, /^https:\/\/app.virtualhallway\.ca/, /^https:\/\/expo.virtualhallway\.ca\/api/, /^https:\/\/expo.virtualhallway\.ca/], //TODO: set from pipelines mainapiurl
  ignoreErrors: ['CapacitorCallPlugin', 'PushNotifications', 'Pusher', 'sentryFormWidget.removeFromDom is not a function', 'axios', 'CapacitorNativeBiometric', 'PlayInterrupted'], //TODO: put everything in it's own config file
  ignoreTransactions: '',
  tracesSampleRate: 1.0, //  Capture 10% of the transactions, set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  replaysSessionSampleRate: 0.1, // (Session Replay) sets the sample rate at 10%. You may want to change it to 100% while in development.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  denyUrls: '',
};

export default sentryConfig;
