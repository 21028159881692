<template>
  <div id="sidebar" class="layout-sidebar-container">
    <div class="overflow-y-auto">
      <div class="cursor-pointer layout-profile" @click="accountOnClick">
        <div class="layout-profile-items">
          <div class="layout-profile-item">
            <template v-if="loggedInUser.profile">
              <img v-bind:src="loggedInUser.profile.profile_picture_location" class="object-cover w-10 h-10 mr-1 bg-white rounded-full" v-if="loggedInUser.profile.profile_picture_location" />
            </template>
            <div class="w-12 h-12 mr-2 bg-white rounded-full" v-else>
              <i class="pi pi-user sidebar-router-icon"></i>
            </div>
          </div>
          <div class="text-left truncate" id="sideBarUserInfoDiv" v-if="expanded">
            <div class="layout-profile-name" v-if="loggedInUser.first_name">
              {{ loggedInUser.first_name + ' ' + loggedInUser.last_name }}
            </div>
            <div class="layout-profile-item" id="layout-profile-email">
              {{ loggedInUser.email }}
            </div>
          </div>
        </div>
      </div>
      <div class="relative layout-sidebar-menu">
        <div class="flex flex-col items-center w-full p-2 layout-sidebar-link-wrapper">
          <router-link v-if="canViewSideBarItemsByRole" class="sidebar-router-link p-ripple group" v-ripple to="/pharm-chat" @click="pharmChatOnClick">
            <div class="sidebar-router-label">Pharmacy Chat</div>
            <i class="pi pi-comments sidebar-router-icon"></i>
          </router-link>

          <router-link v-if="canViewSideBarItemsByRole" class="sidebar-router-link p-ripple group" v-ripple to="/smart-path-recommendation" @click="pathwaysOnClick(event)">
            <div class="sidebar-router-label">Smart Path Recommendation</div>
            <i class="fa-solid fa-robot sidebar-router-icon"></i>
          </router-link>

          <div class="w-full" v-if="isOrgUnitHead(loggedInUser) && canViewSideBarItemsByRole">
            <OrgSideBarItems @menu-toggle="$emit('menu-toggle', event)" />
          </div>

          <div class="w-full" v-if="isBookingHallwayAdmin(loggedInUser)">
            <router-link class="sidebar-router-link p-ripple group" v-ripple to="/manageusers" @click="$emit('menu-toggle', event)">
              <div class="sidebar-router-label">Users</div>
              <i class="pi pi-users sidebar-router-icon"></i>
            </router-link>
          </div>

          <div v-if="canMakeReferrals(loggedInUser) && canViewSideBarItemsByRole" class="w-full">
            <GpSideBarItems @menu-toggle="$emit('menu-toggle', event)" />
          </div>

          <div v-if="canSetAvailability(loggedInUser) && canViewSideBarItemsByRole" class="w-full">
            <SpSideBarItems @menu-toggle="$emit('menu-toggle', event)" />
          </div>

          <!-- Disabled sidebar items for CME/Preboarding users -->
          <template v-if="isCme(loggedInUser) || isPreboarding(loggedInUser)">
            <div id="book-a-consult" class="sidebar-router-link-disabled p-ripple group" v-ripple @click="accountSetupOnClick" v-tooltip.right="{ value: isCme(loggedInUser) ? 'Your account is limited to CME lectures. Please upgrade your account setup to enable this feature.' : 'Please complete your account setup to enable this feature.' }">
              <div class="sidebar-router-label">Book a Consult</div>
              <i class="pi pi-id-card sidebar-router-icon"></i>
            </div>
            <div class="sidebar-router-link-disabled p-ripple group" v-ripple @click="accountSetupOnClick" v-tooltip.right="{ value: isCme(loggedInUser) ? 'Your account is limited to CME lectures. Please upgrade your account setup to enable this feature.' : 'Please complete your account setup to enable this feature.' }">
              <div class="sidebar-router-label">My Calendar</div>
              <i class="pi pi-calendar sidebar-router-icon"></i>
            </div>
            <div class="sidebar-router-link-disabled p-ripple group" v-ripple @click="accountSetupOnClick" v-tooltip.right="{ value: isCme(loggedInUser) ? 'Your account is limited to CME lectures. Please upgrade your account setup to enable this feature.' : 'Please complete your account setup to enable this feature.' }">
              <div class="sidebar-router-label">My Consults</div>
              <i class="pi pi-list sidebar-router-icon"></i>
            </div>
            <div class="sidebar-router-link-disabled p-ripple group" v-ripple @click="accountSetupOnClick" v-tooltip.right="{ value: isCme(loggedInUser) ? 'Your account is limited to CME lectures. Please upgrade your account setup to enable this feature.' : 'Please complete your account setup to enable this feature.' }">
              <div class="sidebar-router-label">Billing</div>
              <i class="pi pi-money-bill sidebar-router-icon"></i>
            </div>
          </template>

          <router-link v-if="canViewSideBarItemsByRole" class="sidebar-router-link p-ripple group" v-ripple to="/home" @click="calendarOnClick(event)">
            <div v-if="isOrgUnitHead(loggedInUser)" class="sidebar-router-label">Calendar</div>
            <div v-else class="sidebar-router-label">My Calendar</div>
            <i class="pi pi-calendar"></i>
          </router-link>

          <router-link v-if="canViewSideBarItemsByRole" class="sidebar-router-link p-ripple group" v-ripple to="/referralhistory" @click="consultOnClick">
            <span id="my-consults-tour" class="flex justify-end w-full ml-14"></span>
            <div v-if="isOrgUnitHead(loggedInUser)" class="sidebar-router-label">Consults</div>
            <div v-else class="sidebar-router-label">My Consults</div>
            <i class="pi pi-list sidebar-router-icon"></i>
          </router-link>

          <router-link class="sidebar-router-link p-ripple group" to="/lectures" v-ripple @click="cmeLecturesOnClick" id="cmeLectures">
            <span id="lectures" class="ml-16"></span>
            <div class="sidebar-router-label">CME Lectures</div>
            <i class="pi pi-video sidebar-router-icon" />
            <span class="absolute flex w-3 h-3 -mt-2 -ml-2" v-if="currentlyLiveLectures.length > 0">
              <span class="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
              <span class="relative inline-flex w-3 h-3 bg-red-500 rounded-full"></span>
            </span>
          </router-link>

          <router-link class="sidebar-router-link p-ripple group" to="/poems" v-ripple @click="cmePoemsOnClick" id="cmePoems" v-if="displayPoems()">
            <div class="sidebar-router-label">CME POEMs</div>
            <i class="pi pi-book sidebar-router-icon" />
          </router-link>

          <router-link class="sidebar-router-link p-ripple group" to="/course-topics" v-ripple @click="coursesOnClick" id="education">
            <div class="sidebar-router-label">Courses</div>
            <i class="pi pi-graduation-cap sidebar-router-icon" />
            <!-- use for tours later on -->
            <!-- <span class="absolute flex w-3 h-3 -mt-2 -ml-2" v-if="currentlyLiveLectures.length > 0">
              <span class="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
              <span class="relative inline-flex w-3 h-3 bg-red-500 rounded-full"></span>
            </span> -->
          </router-link>

          <router-link v-if="canViewSideBarItemsByRole" class="sidebar-router-link p-ripple group" v-ripple to="/resources" @click="resourcesOnClick" id="sideBarResourcesLink">
            <span id="resources" class="ml-16"></span>
            <div class="sidebar-router-label">Resources</div>
            <i class="pi pi-file sidebar-router-icon"></i>
          </router-link>

          <router-link v-if="isGP(loggedInUser) && isPartOfGeneralVhcHallway(loggedInUser) && smartPathAccess(loggedInUser) && !isPreboarding(loggedInUser)" class="sidebar-router-link p-ripple group" v-ripple to="/pathwayhistory" @click="pathwaysOnClick(event)">
            <div class="sidebar-router-label">My Pathways</div>
            <i class="pi pi-share-alt sidebar-router-icon"></i>
          </router-link>

          <router-link class="sidebar-router-link p-ripple group" v-ripple to="/myaccount" @click="accountOnClick">
            <div class="sidebar-router-label">Account</div>
            <i class="pi pi-cog sidebar-router-icon"></i>
          </router-link>

          <div class="sidebar-router-link p-ripple group" v-ripple @click="supportOnClick">
            <div class="sidebar-router-label">Support</div>
            <i class="pi pi-question-circle sidebar-router-icon"></i>
          </div>

          <router-link v-if="isPreboarding(loggedInUser)" class="sidebar-router-link p-ripple group" v-ripple to="/account-setup" @click="accountSetupOnClick">
            <div class="sidebar-router-label">Account Setup</div>
            <i class="pi pi-user-edit sidebar-router-icon"></i>
          </router-link>

          <div @click="notesOnclick" class="sidebar-router-link p-ripple group" id="encounterNoteLink" :class="{ 'bg-secondary !text-white hover:bg-gray-800': $route.path.includes('note') }" v-ripple v-if="!isPreboarding(loggedInUser) && !isNativePlatform() && (isGP(loggedInUser) || isSpecialist(loggedInUser)) && !hasProvinceOfPractice(loggedInUser, ['ON'])">
            <span id="notes" class="ml-16"></span>
            <div class="sidebar-router-label">Notes</div>
            <i class="pi pi-calendar-plus sidebar-router-icon"></i>
          </div>

          <router-link class="sidebar-router-link p-ripple group" v-ripple to="/billing" @click="billingOnClick" v-if="canBill()">
            <span id="billing" class="ml-16"></span>
            <div class="sidebar-router-label">Billing</div>
            <i class="pi pi-download sidebar-router-icon"></i>
          </router-link>

          <div class="sidebar-router-link p-ripple group" :class="isNativePlatform() ? '' : 'mt-12'" v-ripple @click="logoutOnClick" name="logout">
            <div class="sidebar-router-label">Log Out</div>
            <i class="pi pi-sign-out sidebar-router-icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GpSideBarItems from '../gp/GpSideBarItems.vue';
import SpSideBarItems from '../specialist/SpSideBarItems.vue';
import OrgSideBarItems from '../org_unit/OrgSideBarItems.vue';
import Ripple from 'primevue/ripple';
import Skeleton from 'primevue/skeleton';

export default {
  inject: ['mixpanel'],
  emits: ['menu-toggle', 'help-toggle'],
  props: ['expanded'],
  components: {
    GpSideBarItems,
    SpSideBarItems,
    OrgSideBarItems,
    Skeleton,
  },
  directives: {
    ripple: Ripple,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isOrgUnitHead', 'isGP', 'isCme', 'canMakeReferrals', 'canSetAvailability', 'actingUser', 'allReferrals', 'isSpecialist', 'isPreboarding', 'orgUnitUsers', 'isActive', 'requiresToUploadBillingArrangement', 'hasProvinceOfPractice', 'selectedEncounterNoteId', 'currentlyLiveLectures']),
    canViewSideBarItemsByRole() {
      if (this.isOrgUnitHead(this.loggedInUser)) {
        return this.isActive(this.loggedInUser);
      }
      if (this.isBookingHallwayAdmin(this.loggedInUser)) {
        return false;
      }
      return !this.isPreboarding(this.loggedInUser);
    },
  },
  methods: {
    supportOnClick() {
      this.$emit('menu-toggle');
      this.$emit('help-toggle');
      this.mixpanel.track('Sidebar - Clicked Support');
    },
    notesOnclick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked Notes');
      if (this.selectedEncounterNoteId) {
        this.$router.push(`/note/${this.selectedEncounterNoteId}`);
      } else {
        this.$router.push('/notes');
      }
    },
    logoutOnClick() {
      this.mixpanel.track('Sidebar - Clicked Logout');
      this.$toast.removeAllGroups();
      this.$router.replace('/logout');
    },
    coursesOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked Courses');
      this.$router.push({ path: '/course-topics' });
    },
    cmeLecturesOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked CME Lectures');
      this.$router.push({ path: '/lectures' });
    },
    cmePoemsOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked CME Poems');
      this.$router.push({ path: '/poems' });
    },
    accountSetupOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked Account Setup');
      this.$router.push({ path: '/account-setup' });
    },
    accountOnClick() {
      if (!this.isPreboarding(this.loggedInUser)) {
        this.$emit('menu-toggle');
        this.$router.push({ path: '/myaccount' });
        this.mixpanel.track('Sidebar - Clicked Account');
      }
    },
    pathwaysOnClick(event) {
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked Pathways');
    },
    consultOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked My Consults');
    },
    calendarOnClick(event) {
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked My Calendar');
    },
    resourcesOnClick(event) {
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked Resources');
    },
    billingOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked Billing');
    },
    canBill() {
      return !this.isPreboarding(this.loggedInUser) && !this.isBookingHallwayAdmin(this.loggedInUser) && this.loggedInUser.provider_type != 'Pharmacist' && (!this.isOrgUnitHead(this.loggedInUser) || this.isActive(this.loggedInUser));
    },
    pharmChatOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked Pharmacy Chat');
    },
    smartPathRecommendationOnClick() {
      this.$emit('menu-toggle');
      this.mixpanel.track('Sidebar - Clicked Smart Path Recommendation');
    },
  },
};
</script>

<style scoped>
i {
  font-size: 1.2rem !important;
}
</style>
